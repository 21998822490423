<template>
  <v-container
    fill-height
    fluid
    grid-list-xl>
    <v-layout
      justify-center
      wrap
    >
      <!--      <v-flex-->
      <!--        xs12-->
      <!--        md3-->
      <!--      >-->
      <!--        <material-card class="v-card-profile">-->
      <!--          <v-avatar-->
      <!--            slot="offset"-->
      <!--            class="mx-auto d-block"-->
      <!--            size="130"-->
      <!--          >-->
      <!--            <img-->
      <!--              src=""-->
      <!--            >-->
      <!--          </v-avatar>-->
      <!--          <v-card-text class="text-xs-center">-->
      <!--            <h6 class="category text-gray font-weight-thin mb-3">CEO / CO-FOUNDER</h6>-->
      <!--            <h4 class="card-title font-weight-light">Alec Thompson</h4>-->

      <!--          </v-card-text>-->
      <!--        </material-card>-->
      <!--      </v-flex>-->
      <v-flex
        xs12
      >
        <v-btn
          class="mx-3 font-weight-light"
          color="green"
          @click="showUserPasswordDialog"
        >
          <v-icon>mdi-account-edit</v-icon>
        </v-btn>
      </v-flex>
      <v-flex
        xs12
        md12
      >
        <material-card
          color="green"
          title="个人信息表"
        >
          <v-form>
            <v-container py-0>
              <v-layout wrap>
                <v-flex
                  xs12
                  md12
                >
                  <v-text-field
                    v-model="userInfo.username"
                    label="用户名"
                    disabled/>
                </v-flex>
                <v-flex
                  xs12
                  md12
                >
                  <v-text-field
                    label="公司"
                  />
                </v-flex>
                <v-flex
                  xs12
                  md12
                >
                  <v-text-field
                    label="地址"
                    class="purple-input"/>
                </v-flex>
                <v-flex
                  xs12
                  md12
                >
                  <v-text-field
                    label="联系方式"
                    class="purple-input"/>
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>
        </material-card>
      </v-flex>

    </v-layout>
    <!--弹出用户密码修改信息 S-->
    <v-dialog
      v-model="showhide.UserPasswordDialog"
      max-width="500"
      persistent
      @keydown.esc="showhide.UserPasswordDialog = false"
    >
      <v-card
        style="background: #eee;">
        <v-flex
          class="d-flex justify-center mb-6">
          <material-card
            max-width="90%"
            color="green"
          >
            <div
              slot="header"
            >
              <div class="mb-2 d-flex">
                <span class="title font-weight-bold mb-2">修改密码</span>
                <span class="text-xs-right">
                  <v-icon
                    size = "24"
                    @click = "closeUserPasswordDialog"
                  >mdi-close-circle</v-icon>
                </span>
              </div>
            </div>
            <v-flex
              xs12
              md12
            >
              <v-text-field
                v-model="userPasswordDialog.username"
                label="用户名"
                readonly
                @keyup.enter="modifyUserPassword"
              />
              <v-text-field
                v-model="userPasswordDialog.password"
                :append-icon="showhide.userPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showhide.userPassword ? 'text' : 'password'"
                hint="密码最少6位"
                label="密码"
                clearable
                @click:append="showhide.userPassword = !showhide.userPassword"
                @keyup.enter="modifyUserPassword"
              />
              <v-text-field
                v-model="userPasswordDialog.newPassword"
                :append-icon="showhide.userNewPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showhide.userNewPassword ? 'text' : 'password'"
                hint="密码最少6位"
                label="新密码"
                clearable
                @click:append="showhide.userNewPassword = !showhide.userNewPassword"
                @keyup.enter="modifyUserPassword"
              />
              <v-text-field
                v-model="userPasswordDialog.conformPassword"
                :append-icon="showhide.userConformPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showhide.userConformPassword ? 'text' : 'password'"
                hint="密码最少6位"
                label="确认新密码"
                clearable
                @click:append="showhide.userConformPassword = !showhide.userConformPassword"
                @keyup.enter="modifyUserPassword"
              />
            <!--              <v-text-field-->
            <!--                v-model="memberPasswordDialog.id"-->
            <!--                label="级别"-->
            <!--                required-->
            <!--                @keyup.enter="addMember"-->
            <!--              />-->
            </v-flex>
          </material-card>
        </v-flex>
        <v-container py-2>
          <v-flex
            xs12
            text-xs-right
            style="margin-right:20px"
          >
            <v-btn
              class="mx-1 font-weight-light"
              color="success"
              @click="modifyUserPassword"
            >
              确认修改
            </v-btn>
          </v-flex>
        </v-container>
      </v-card>
    </v-dialog>
    <!--弹出成员密码修改信息 E-->
  </v-container>

</template>

<script>
export default {
  data: () => ({
    userInfo: {
      username: '',
      password: ''
    },
    showhide: {
      UserPasswordDialog: false,
      userPassword: false,
      userNewPassword: false,
      userConformPassword: false
    },
    userPasswordDialog: {
      username: '',
      password: '',
      newPassword: '',
      conformPassword: ''
    }
  }),
  created () {
    this.$checkAuth()
    // console.log(this.$store.state.app.signIn.username)
    this.userInfo.username = this.$store.state.app.signIn.username
  },
  methods: {
    showUserPasswordDialog () {
      this.userPasswordDialog.username = this.$store.state.app.signIn.username
      this.showhide.UserPasswordDialog = true
    },
    closeUserPasswordDialog () {
      this.showhide.UserPasswordDialog = false
    },
    modifyUserPassword () {
      let formdata = new FormData()
      formdata.append('Password', this.userPasswordDialog.password)
      formdata.append('NewPassword', this.userPasswordDialog.newPassword)
      formdata.append('ConformPassword', this.userPasswordDialog.conformPassword)
      let headers = {
        headers: {
          'Authorization': this.$store.state.app.signIn.token
        }
      }
      this.$http
        .post(this.$store.state.app.url + '/mediaSystem/user/updatePassword', formdata, headers)
        .then(res => {
          if (res.data.code === 0) {
            this.closeUserPasswordDialog()
            console.log(res.data.message)
            console.log(res.data)
          }
        }, (error) => {
          console.log(error.message)
        })
    }
  }

}
</script>
